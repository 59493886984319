@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.wrapper {
  max-width: 1080px;
  margin: 0px auto;
  padding: 0 20px;
  position: relative;
  /* overflow: hidden; */
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: rgb(36, 37, 35);
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .row {
  display: flex;
  /* flex-direction: column; */
}
.wrapper .left {
  justify-content: flex-start;
}
.wrapper .right {
  justify-content: flex-end;
}
.wrapper .row section {
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.details {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
}

/* .wrapper .row section::before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: grey;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
} */
.left section::before {
  right: -7px;
}
.right section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: black;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: white;
  font-size: 17px;
  /* box-shadow: 0 0 0 4px grey, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05); */
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}

.left section .icon {
  top: 15px;
  right: -60px;
}
.right section .icon {
  top: 15px;
  left: -60px;
}
.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
  width: 100%;
}
.row section .details .subtitle {
  font-size: 18px;
  font-weight: 200;
  width: 100%;
}
.row section p {
  margin: 10px 0 17px 0;
}

@media (max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 5px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .left section::before {
    left: -7px;
  }
  .left section .icon {
    left: -60px;
  }
  .row section .details .title {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
  }
  .row section .details {
    padding-bottom: 10px;
  }
  .row section p {
    margin: 10px 0 10px 0;
  }
}
@media (max-width: 440px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }

  .wrapper .row {
    margin: 10px 0;
  }
}
