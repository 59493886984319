.t {
  width: 50px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid grey;
  background-color: white;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.t img {
  width: 15px;
  height: 15px;
}

.t-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #999;
  position: absolute;
  cursor: pointer;
  right: 0;
}
