.i {
    display: flex;
    height: 100vh;
}

.i-right {
    flex: 1;
    position: relative;
}

.i-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.i-left-wrapper {
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro {
    font-size: 30px;
    font-weight: 300;
}

.i-name {
    font-size: 60px;
}

.i-title{
    height: 50px;
    overflow: hidden;
    font-size: 30px;}

/* .i-title-wrapper {
    height: 100%;
    animation: move 8s ease-in-out infinite alternate;
}

@keyframes move {
    33% {
        transform: translateY(-50px);
    }
    66% {
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(-150px);
    }
    
} */

/* .i-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #0093E9;
    display: flex;
    align-items: center;
} */

.i-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position:absolute;
}

.i-bg {
    /* clip-path: polygon(25% 0%, 90% 0, 90% 100%, 25% 100%); */
    clip-path: circle(56.3% at 65% 41%);
    /* clip-path: inset(5% 20% 15% 10%); */
    /* clip-path: polygon(0 78%, 90% 0, 90% 100%, 0 23%); */
      background: #8360c3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #8360c3, #2ebf91); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #8360c3, #2ebf91);
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
}

.i-scroll {
    width: 30px;
    height: 30px;
    position:absolute;
    bottom: 20px;
    left: 25%
}

@media screen and (max-width: 480px) {
    .i {
        flex-direction: column;
    }
    .i-left-wrapper {
        padding: 10px;
        align-content: center;
        text-align: center;
        justify-content: center;
        height: 100%;
    }
    
    .i-title-item {
    height: 50px;
    font-size: 25px;
    align-content: center;
    }
    .i-name {
    font-size: 40px;
}

}