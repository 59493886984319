.p{
    width: 30%;
    height: 35vh;
    margin: 20px 10px;
    border: 2px solid rgb(243,242,243);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
}

.p-browser {
    height: 20px;
    background-color: rgb(22, 20, 22);
    color: white;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
    
}

.p-company{
font-size: 12px;
margin-left: 20px;
}

.p-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    margin: 1px;
}

.p-img{
    width: 100%;
    transition: all 10s ease;
}

/* .p:hover .p-img {
    transform: translateY(-100%);
} */


@media screen and (max-width: 480px) {

    .p{
        width: 40%;
        height: 20vh;
    }
    
}
