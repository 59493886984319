.e {
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
      margin-bottom: 5%;
}
.e-title {
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 20px;
}

.timeline-wrapper {
  border-top: black 1px solid;
  border-bottom: black 1px solid;
  width: 80%;
  height: auto;
  align-content: center;
  /* overflow-y: hidden; */
}
