
.a {
    display: flex;
    height: 100vh;
    align-items: center;
/* background-color: rgb(131, 202, 89); */
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}
.a-right {
    flex: 1;

}

.a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

}

.a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    /* background-color: #333; */
      background: #8360c3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #8360c3, #2ebf91); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #8360c3, #2ebf91); 
}

.a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.a-award-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
}

.a-title{
    font-weight: 400;
}

.a-sub{
    margin: 20px 0px;
}

.a-desc{
    font-weight: 300;
}

.a-award-text{
    width: 70%;
}

.a-award-title{
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.a-award{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 480px) {
    .a {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 50px;;
        height: fit-content;
    }
    .a-left {
        width: 100%;
    }

    .a-card.bg,.a-award,.a-img,.a-card {
        display: none;
    }

    .a-right {
        padding: 20px;
    }

}